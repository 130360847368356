/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'charts': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M41.432 48.432a1.334 1.334 0 00-1.747-.32 17.28 17.28 0 01-9.018 2.555c-9.558 0-17.334-7.776-17.334-17.334 0-8.554 6.387-15.92 14.856-17.133a1.335 1.335 0 001.144-1.32V4c0-.736-.597-1.333-1.333-1.333-15.44 0-28 13.757-28 30.666C0 50.243 13.757 64 30.667 64c7.565 0 11.658-1.37 16.845-5.637a1.334 1.334 0 00.205-1.848l-6.285-8.083zM30.667 61.333c-15.44 0-28-12.56-28-28 0-14.944 10.648-27.192 24-27.962v8.386c-9.198 1.875-16 10.094-16 19.576 0 11.03 8.97 20 20 20 3.264 0 6.48-.81 9.37-2.349l4.768 6.128c-4.21 3.213-7.722 4.221-14.138 4.221zm2.666-48c9.558 0 17.334 7.776 17.334 17.334 0 .736.597 1.333 1.333 1.333h10.667c.736 0 1.333-.597 1.333-1.333C64 13.757 50.243 0 33.333 0 32.597 0 32 .597 32 1.333V12c0 .736.597 1.333 1.333 1.333zM34.667 2.7c14.381.677 25.957 12.253 26.634 26.634h-8.013c-.659-9.968-8.656-17.962-18.621-18.621V2.699zm28 31.968H51.789c-.664 0-1.226.488-1.322 1.144a17.283 17.283 0 01-5.963 10.765 1.332 1.332 0 00-.176 1.856l7.968 9.864c.232.29.573.467.944.493a1.342 1.342 0 001.003-.355A30.786 30.786 0 0064 36c0-.736-.597-1.333-1.333-1.333zm-9.219 20.81l-6.253-7.741a19.952 19.952 0 005.717-10.403h8.39a28.133 28.133 0 01-7.854 18.144z" _fill="#B4BAC6"/>'
  }
})
